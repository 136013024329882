import React from 'react';
import HeroSection from './HeroSection';
// import AboutUs from './AboutUsSection'; // This is the summary About Us section, not the detailed page
import ServicesSection from './ServiceSection';
import ContactUs from './ContactUs';
import TestimonialsSection from './TestimonialSection';
import TestimonialsReview from './TestimonialsReviews';
import FeaturedPodcast from './FeaturedPodcast';


const Home = () => {
  return (
    <div>
      <HeroSection />
      {/* <AboutUs /> Short About Us section */}
      <ServicesSection />
      {/* <TestimonialsSection />
      <TestimonialsReview/> */}
      <FeaturedPodcast />
      <ContactUs />
    </div>
  );
};

export default Home;
